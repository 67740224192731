<template>
<b-card>

    <validation-observer ref="simpleRules">
        <b-form>
            <!-- Section Informations -->
            <h4>Informations</h4>
            <hr style="margin-bottom: 16px;">
            <b-row>

                <!-- Field: Asset -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Asset')" label-for="asset">
                        <vue-autosuggest v-if="!fuellingData.id" v-model="searchQuery" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.plate ?  suggestion.item.plate : suggestion.item.chassisNumber}}</span>
                            </template>
                        </vue-autosuggest>

                        <b-form-input v-if="fuellingData.id" id="asset" v-model="searchQuery" disabled />
                    </b-form-group>
                </b-col>

                <!-- Field: Date -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Date')" label-for="date">
                        <validation-provider #default="{ errors }" name="date" rules="required">
                            <flat-pickr v-model="fuellingData.dateTime" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Fuel Type -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Fuel Type')" label-for="fuel-type">
                        <validation-provider #default="{ errors }" name="fuel-type" rules="required">
                            <v-select v-model="fuellingData.fuelType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="fuelOptions" :reduce="val => val.value" :clearable="false" input-id="fuel-type" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field:  liters -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Liters')" label-for="liters">
                        <validation-provider #default="{ errors }" name="liters" rules="required">
                            <cleave id="value" v-model="fuellingData.liters" class="form-control" :raw="false" :options="optMaskValue" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field:  value -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Value')" label-for="value">
                        <validation-provider #default="{ errors }" name="value" rules="required">
                            <cleave id="value" v-model="fuellingData.value" class="form-control" :raw="false" :options="optMaskValue" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field:  odometer -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Odometer')" label-for="odometer">
                        <validation-provider #default="{ errors }" name="odometer" rules="regex:^([0-9]+)$">
                            <b-form-input id="odometer" v-model="fuellingData.odometer" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field:  hourmeter -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Hourmeter')" label-for="hourmeter">
                        <validation-provider #default="{ errors }" name="hourmeter" rules="regex:^([0-9]+)$">
                            <b-form-input id="hourmeter" v-model="fuellingData.hourmeter" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm">
                        {{$t('Save')}}
                    </b-button>
                </b-col>
            </b-row>

        </b-form>
    </validation-observer>
</b-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import managementStoreModule from '../managementStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import {
    VueAutosuggest
} from 'vue-autosuggest'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    confirmed,
    url,
    between,
    alpha,
    integer,
    password,
    min,
    max,
    digits,
    alphaDash,
    length,
} from '@validations'

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,

        Cleave,
        vSelect,
        VueAutosuggest,
        flatPickr

    },
    data() {
        return {
            myId: router.currentRoute.params.id,
            required,
            email,
            confirmed,
            url,
            between,
            alpha,
            integer,
            password,
            min,
            digits,
            alphaDash,
            length,
            max,

            optMaskValue:{
                numeral: true,
                numeralDecimalMark: ",",
                numeralDecimalScale: 2,
                delimiter: "",
                numeralIntegerScale:5

            },

            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for your asset ...",
            },
            limit: 5,
            selected: null,
        }
    },
    computed: {

    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.fuellingData.value = this.fuellingData.value.replaceAll(',','.')
                    this.fuellingData.liters = this.fuellingData.liters.replaceAll(',','.')

                    if (this.fuellingData.id === "") {
                        store.dispatch('app-management/addFuelling', {
                                
                                fuellingData: this.fuellingData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog('Successful Fuelling registration', 'success')

                                router.push({
                                    name: 'list-fuelling'
                                })

                            })
                            .catch(error => {
                                this.msgDialog('FAILURE to register Fuelling', 'error')
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                            })
                    } else {

                        store.dispatch('app-management/updateFuelling', {

                                fuellingData: this.fuellingData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog('Successful Fuelling update', 'success')

                                //this.clearForm();

                            })
                            .catch(error => {
                                this.msgDialog('FAILURE to update Fuelling', 'error')

                            })
                    }
                }
            })
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },

        selectHandler(option) {
            this.selected = option.item
            this.filteredOptions = []
            this.searchQuery = option.item.plate ? option.item.plate : option.item.chassisNumber
            this.fuellingData.assetId = option.item.id

        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-management/fetchAssets', {
                    q: this.searchQuery,

                })
                .then(response => {

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },
        suggestionSelected() {
            console.log('Selecionado', this.selected)
        }
    },
    setup() {
        const fuellingData = ref(null)
        const searchQuery = ref(``)

        const fuelOptions = ref([])

        const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
        })

        //Fetch type fuel
        store.dispatch('app-management/fetchTypeFuel')
            .then(response => {
                
                response.data.data.map(function (v, key) {
                    console.log(`RESPOSTA FUEL OPT`, v.name)
                    fuelOptions.value.push({
                        label: v.name,
                        value: v.id
                    }, );
                });

            })
            .catch(error => {

            })

        if (router.currentRoute.params.id != undefined) {

            store.dispatch('app-management/fetchFuelling', {
                    //id: router.currentRoute.params.id
                    id: router.currentRoute.params.id
                })
                .then(response => {
                    response.data.value = response.data.value.toString().replaceAll('.',',')
                    response.data.liters = response.data.liters.toString().replaceAll('.',',')
                    fuellingData.value = response.data

                    store.dispatch('app-management/fetchAsset', {
                            //id: router.currentRoute.params.id
                            id: response.data.assetId
                        })
                        .then(res => {
                            searchQuery.value = res.data.plate ? res.data.plate : res.data.chassisNumber;

                        })
                        .catch(error => {

                        })
                })
                .catch(e => {
                    console.log(e)
                })
        } else {
            fuellingData.value = {
                id: '',
                assetId: ``,
                dateTime: ``,
                fuelType: '',
                liters: '',
                value: '',
                odometer: null,
                hourmeter: null,

            }

        }

        return {
            fuellingData,
            searchQuery,
            fuelOptions

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style><style>
.title-head {
    margin-bottom: 32px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',[_vm._v("Informations")]),_c('hr',{staticStyle:{"margin-bottom":"16px"}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Asset'),"label-for":"asset"}},[(!_vm.fuellingData.id)?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps},on:{"selected":_vm.selectHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.plate ? suggestion.item.plate : suggestion.item.chassisNumber))])]}}],null,false,230114594),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e(),(_vm.fuellingData.id)?_c('b-form-input',{attrs:{"id":"asset","disabled":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Date'),"label-for":"date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.fuellingData.dateTime),callback:function ($$v) {_vm.$set(_vm.fuellingData, "dateTime", $$v)},expression:"fuellingData.dateTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Fuel Type'),"label-for":"fuel-type"}},[_c('validation-provider',{attrs:{"name":"fuel-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.fuelOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"fuel-type"},model:{value:(_vm.fuellingData.fuelType),callback:function ($$v) {_vm.$set(_vm.fuellingData, "fuelType", $$v)},expression:"fuellingData.fuelType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Liters'),"label-for":"liters"}},[_c('validation-provider',{attrs:{"name":"liters","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"value","raw":false,"options":_vm.optMaskValue},model:{value:(_vm.fuellingData.liters),callback:function ($$v) {_vm.$set(_vm.fuellingData, "liters", $$v)},expression:"fuellingData.liters"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Value'),"label-for":"value"}},[_c('validation-provider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"value","raw":false,"options":_vm.optMaskValue},model:{value:(_vm.fuellingData.value),callback:function ($$v) {_vm.$set(_vm.fuellingData, "value", $$v)},expression:"fuellingData.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Odometer'),"label-for":"odometer"}},[_c('validation-provider',{attrs:{"name":"odometer","rules":"regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"odometer"},model:{value:(_vm.fuellingData.odometer),callback:function ($$v) {_vm.$set(_vm.fuellingData, "odometer", $$v)},expression:"fuellingData.odometer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Hourmeter'),"label-for":"hourmeter"}},[_c('validation-provider',{attrs:{"name":"hourmeter","rules":"regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hourmeter"},model:{value:(_vm.fuellingData.hourmeter),callback:function ($$v) {_vm.$set(_vm.fuellingData, "hourmeter", $$v)},expression:"fuellingData.hourmeter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }